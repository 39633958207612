<template>
  <div class="uc-spread-center">
    <div class="trade-container">
      <div class="header">
        <div class="content-title">
          <h2>
            {{ translateTitle("邀请好友，共同赚币") }}
          </h2>
          <span class="sub-title">{{
            translateTitle("您的好友与您共同享受返佣")
          }}</span>
        </div>
      </div>
      <div class="title-content">
        <div class="left-content">
          <div class="left-img">
            <el-avatar :size="48" :src="avatar" fit="fill">
              <img src="../../../../assets/img/st-icon-1-1.png" />
            </el-avatar>
            <p class="nick-name">{{ nick_name ? nick_name : "--" }}</p>
            <p class="uid num DINPro">
              UID：{{ userInfo.userInfo ? userInfo.userInfo.user_id : "--" }}
            </p>
          </div>
          <div class="left-text">
            <p class="left-text-title">
              {{ translateTitle("邀请总览") }}
            </p>
            <div class="left-content-item">
              <p class="one-item">{{ translateTitle("邀请人数") }}</p>
              <p class="two-item ">
                <span class="num DINPro">{{
                  total_stats.total_invite_user_num || "0"
                }}</span
                >{{ translateTitle("人") }}
              </p>
              <p class="three-item">
                <span class="num DINPro">
                  +
                  {{ total_stats.intvite_user_num || "0" }}</span
                >
              </p>
            </div>
            <div class="left-content-item">
              <p class="one-item">{{ translateTitle("已交易人数") }}</p>
              <p class="two-item">
                <span class="num DINPro">{{
                  total_stats.total_trade_user_num
                }}</span
                >{{ translateTitle("人") }}
              </p>
              <p class="three-item">
                <span class="num DINPro">
                  +
                  {{ total_stats.trade_user_num || "0" }}</span
                >
              </p>
            </div>
            <div class="left-content-item">
              <p class="one-item">{{ translateTitle("我的返利") }}</p>
              <p class="two-item">
                <span class="num DINPro">{{
                  total_stats.total_rebate_amount
                }}</span
                >usdt
              </p>
              <p class="three-item">
                <span class="num DINPro">
                  +
                  {{ total_stats.rebate_amount || "0" }}</span
                >
              </p>
            </div>
          </div>
        </div>
        <div class="right-content">
          <div class="right-text">
            <p class="right-title">
              {{ translateTitle("我的邀请") }}
              <span class="real-rebate" v-if="rebate_mode == 2"
                >{{ translateTitle("我的实际返利") }}
                <span class="rebate-value">{{
                  self_real_rebate_rate
                }}</span></span
              ><span class="real-rebate" v-if="rebate_mode == 2"
                >{{ translateTitle("好友实际返利") }}
                <span class="rebate-value">{{
                  target_real_rebate_rate
                }}</span></span
              >
            </p>
            <div class="right-text-item">
              <div class="left">
                <p class="top-text">
                  {{ translateTitle("我的返利") }}
                  <span class="red num DINPro"
                    >{{
                      digData(default_invite_code.self_rebate_rate * 100) ||
                        "0"
                    }}%</span
                  >&nbsp; &nbsp;{{ translateTitle("好友返利") }}
                  <span class="red num DINPro"
                    >{{
                      digData(default_invite_code.target_rebate_rate * 100) ||
                        "0"
                    }}%</span
                  >

                  <el-tooltip
                    :visible-arrow="true"
                    placement="top-start"
                    :popper-options="{
                      boundariesElement: 'viewport',
                      removeOnDestroy: true,
                    }"
                  >
                    <div slot="content">
                      {{ translateTitle("您的返佣包含 理财, 币币交易, 合约等")
                      }}<br />
                      {{
                        translateTitle(
                          "假设您的币币返佣比率为手续费的 20%，您设置 我的返利为"
                        )
                      }}{{
                        digData(default_invite_code.self_rebate_rate * 100) ||
                          "0"
                      }}%
                      {{ translateTitle("好友为") }}
                      {{
                        digData(default_invite_code.target_rebate_rate * 100) ||
                          "0"
                      }}%,<br />{{ translateTitle("则您的最终得到的返佣为") }}
                      {{
                        digData(default_invite_code.self_rebate_rate) * 100 ||
                          "0"
                      }}%*20%, {{ translateTitle("您的好友的返佣为") }}
                      {{
                        digData(default_invite_code.target_rebate_rate * 100) ||
                          "0"
                      }}%*20%<br />
                    </div>
                    <i class="iconfont iconwenhao ask"></i>
                  </el-tooltip>
                </p>
                <p class="invite">
                  {{ translateTitle("默认邀请码") }}：{{
                    default_invite_code.code
                  }}
                  <i
                    class="iconfont iconicon-copy"
                    v-clipboard:copy="default_invite_code.code"
                    v-clipboard:success="copySuccess"
                    v-clipboard:error="copyError"
                  ></i>
                </p>
                <div class="invite_link_outer">
                  <p class="invite">
                    {{ translateTitle("网站邀请链接") }}：{{
                      webHost + invite_url
                    }}
                  </p>
                  <i
                    class="iconfont iconicon-copy inviteIcon"
                    v-clipboard:copy="webHost + invite_url"
                    v-clipboard:success="copySuccess"
                    v-clipboard:error="copyError"
                  ></i>
                </div>
                <div class="invite_link_outer">
                  <p class="invite">
                    {{ translateTitle("APP邀请链接") }}：{{
                      centerHost + invite_url
                    }}
                  </p>
                  <i
                    class="iconfont iconicon-copy inviteIcon"
                    v-clipboard:copy="centerHost + invite_url"
                    v-clipboard:success="copySuccess"
                    v-clipboard:error="copyError"
                  ></i>
                </div>
              </div>
              <div class="right">
                <p @click="onEditDialogOpen(default_invite_code, false)">
                  <i class="iconfont iconedit editcolor"></i>
                  {{ translateTitle("编辑") }}
                </p>
                <span
                  class="btn-v2"
                  @click="onInviteDialogOpen(default_invite_code)"
                >
                  {{ translateTitle("立即邀请") }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="spread-uncter-content" v-loading="tableLoading">
        <div class="tab-content">
          <ul>
            <li
              class="classify-area"
              :class="{ active: currIndex == item.value }"
              v-for="item of tabList"
              :key="item.value"
              @click="onAreaChange(item)"
            >
              {{ translateTitle(item.label) }}
              <i v-if="currIndex == item.value" class="item-bar"></i>
            </li>
          </ul>
        </div>
        <!-- 数据总览开始 -->
        <div v-show="currIndex == 1">
          <!-- 列表 -->
          <div class="filter-box">
            <el-select
              v-model="rebate_type_list_item"
              value-key="rebate_type"
              :placeholder="translateTitle('返利类型')"
              :clearable="true"
            >
              <el-option
                v-for="item in rebate_type_list"
                :key="item.rebate_type"
                :label="item.language[`show_name_${language}`]"
                :value="item"
              >
              </el-option>
            </el-select>
            <div class="tenWid"></div>
            <el-date-picker
              v-model="ctimeAndEtime"
              type="daterange"
              :range-separator="translateTitle('至')"
              :start-placeholder="translateTitle('开始日期')"
              :end-placeholder="translateTitle('结束日期')"
              format="yyyy-MM-dd"
              value-format="timestamp"
            />

            <div class="twentyWid"></div>
            <span class="btn-search" @click="getTotalRebateStats">{{
              translateTitle("查询")
            }}</span>
            <div class="tenWid"></div>
            <span class="btn-reset" @click="resetTotalRebate">{{
              translateTitle("重置")
            }}</span>
            <el-button
              class="btn-export"
              @click="onDownload(1)"
              :loading="exportLoading"
              ><i class="iconfont iconicon-daochu export-icon"></i
              >{{ translateTitle("导出") }}</el-button
            >
          </div>

          <div class="total-msg-box num DINPro">
            {{ translateTitle("总佣金收入")
            }}<span class="msg-num num DINPro"
              >{{
                totalRebateListLoading
                  ? "----"
                  : total_stats_son.total_rebate_amount
                  ? total_stats_son.total_rebate_amount
                  : "0.00"
              }}
              USDT</span
            >{{ translateTitle("已交易好友数量")
            }}<span class="msg-num num DINPro"
              >{{
                totalRebateListLoading
                  ? "-"
                  : total_stats_son.total_trade_user_num
                  ? total_stats_son.total_trade_user_num
                  : 0
              }}
              {{ translateTitle("人") }}</span
            >{{ translateTitle("邀请好友数量")
            }}<span class="msg-num num DINPro"
              >{{
                totalRebateListLoading
                  ? "-"
                  : total_stats_son.total_invite_user_num
                  ? total_stats_son.total_invite_user_num
                  : 0
              }}
              {{ translateTitle("人") }}</span
            >
          </div>

          <el-table
            :data="totalRebateList"
            class="g-table"
            v-loading="totalRebateListLoading"
          >
            <el-table-column
              class="coin-cell"
              width="120px"
              align="left"
              prop="date"
              :label="translateTitle('时间')"
            >
              <template slot-scope="{ row }">
                <span class="text num DINPro">{{
                  row.date
                    .toString()
                    .replace(/^(\d{4})(\d{2})(\d{2})$/, "$1-$2-$3")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              class="coin-cell"
              min-width="80px"
              align="right"
              prop="rebate_amount"
              :label="translateTitle('返利收入') + '(USDT)'"
            >
              <template slot-scope="{ row }">
                <span class="text num DINPro">{{ row.rebate_amount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              class="coin-cell"
              min-width="80px"
              align="right"
              prop="team_user_num"
              :label="translateTitle('团队人数')"
            >
              <template slot-scope="{ row }">
                <span class="text num DINPro">{{ row.team_user_num }}</span>
              </template>
            </el-table-column>
            <el-table-column
              class="coin-cell"
              min-width="80px"
              align="right"
              prop="team_user_num"
              :label="translateTitle('团队交易人数')"
            >
              <template slot-scope="{ row }">
                <span class="text num DINPro">{{
                  row.team_trade_user_num
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              class="coin-cell"
              min-width="80px"
              align="right"
              prop="intvite_user_num"
              :label="translateTitle('邀请好友人数')"
            >
              <template slot-scope="{ row }">
                <span class="text num DINPro">{{ row.intvite_user_num }}</span>
              </template>
            </el-table-column>
            <el-table-column
              class="coin-cell"
              min-width="80px"
              align="right"
              prop="intvite_user_num"
              :label="translateTitle('好友人数')"
            >
              <template slot-scope="{ row }">
                <span class="text num DINPro">{{
                  row.total_invite_user_num
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="80px"
              align="right"
              prop="trade_user_num"
              :label="translateTitle('好友交易人数')"
            >
              <template slot-scope="{ row }">
                <span class="text num DINPro">{{ row.trade_user_num }}</span>
              </template>
            </el-table-column>
            <no-data
              slot="empty"
              v-show="!totalRebateListLoading && totalRebateList.length == 0"
            />
          </el-table>
        </div>
        <!-- 数据总览结束 -->
        <!-- 推荐链接开始 -->
        <div v-show="currIndex == 2">
          <div class="invite-code-box">
            <div class="search-content">
              <el-input
                class="search-code"
                :clearable="true"
                v-model="searchCode"
                maxlength="20"
                :placeholder="translateTitle('推广码')"
              ></el-input>
              <el-input
                class="search-remark"
                :clearable="true"
                v-model="searchRemark"
                maxlength="20"
                :placeholder="translateTitle('备注')"
              ></el-input>
              <span class="btn-search" @click="getInviteCodeStats">{{
                translateTitle("查询")
              }}</span>
              <div class="tenWid"></div>
              <span class="btn-reset" @click="resetInviteCodeStats">{{
                translateTitle("重置")
              }}</span>
            </div>
            <div class="btn-invite" @click="onHolderDialogOpen">
              {{ translateTitle("+新增推广码") }}
            </div>
          </div>

          <el-table
            :data="inviteCodeList"
            class="g-table"
            v-loading="inviteCodeListLoading"
          >
            <el-table-column
              class="coin-cell"
              min-width="50px"
              align="left"
              prop="code"
              :label="translateTitle('推广码')"
            >
              <template slot-scope="{ row }">
                <span class="text">{{ row.code }}</span>
                <i
                  class="iconfont iconicon-copy table-icon-copy"
                  v-clipboard:copy="row.code"
                  v-clipboard:success="copySuccess"
                  v-clipboard:error="copyError"
                ></i>
                <span v-if="row.is_default == 2" class="is-default-code">{{
                  translateTitle("默认")
                }}</span>
              </template>
            </el-table-column>
            <!-- 接口中无好友返利，展示累计返利即我的返利 -->
            <el-table-column
              class="coin-cell"
              min-width="45px"
              align="center"
              prop="self_rebate_rate"
              :label="translateTitle('我的返利/好友返利')"
            >
              <template slot-scope="{ row }">
                <span class="text persent num DINPro">{{
                  digData(row.self_rebate_rate * 100) + "%" + " "
                }}</span>
                {{ "/" }}
                <span class="text persent num DINPro">{{
                  " " + digData(row.target_rebate_rate * 100) + "%"
                }}</span>
              </template>
            </el-table-column>

            <el-table-column
              class="coin-cell"
              min-width="40px"
              align="right"
              prop="invite_user_num"
              :label="translateTitle('好友人数')"
            >
              <template slot-scope="{ row }">
                <span class="text num DINPro">{{ row.invite_user_num }}</span>
              </template>
            </el-table-column>
            <el-table-column
              class="coin-cell"
              min-width="40px"
              align="right"
              prop="trade_user_num"
              :label="translateTitle('好友交易人数')"
            >
              <template slot-scope="{ row }">
                <span class="text num DINPro">{{ row.trade_user_num }}</span>
              </template>
            </el-table-column>
            <el-table-column
              class="coin-cell"
              min-width="80px"
              align="right"
              prop="remark"
              :label="translateTitle('备注')"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="{ row }">
                <span class="text">{{ row.remark }}</span>
              </template>
            </el-table-column>
            <el-table-column
              width="365"
              :label="translateTitle('操作')"
              align="right"
            >
              <template slot-scope="{ row }" style="text-align:right">
                <span
                  v-if="row.is_default != 2"
                  class="operation"
                  @click="setDefaultInviteCode(row.code)"
                  >{{ translateTitle("设为默认") }}</span
                >
                <span v-if="row.is_default == 2" class="operation">{{
                  translateTitle("默认")
                }}</span>
                <span class="operation" @click="onEditDialogOpen(row, true)">{{
                  translateTitle("编辑")
                }}</span>
                <span
                  class="operation"
                  v-clipboard:copy="webHost + row.invite_url"
                  v-clipboard:success="copySuccess"
                  v-clipboard:error="copyError"
                  >{{ translateTitle("网站邀请") }}</span
                >
                <span class="operation" @click="onInviteDialogOpen(row)">{{
                  translateTitle("APP邀请")
                }}</span>
                <!-- <span
                  v-if="row.invite_user_num == 0 && row.is_default != 2"
                  class="operation"
                  @click="onInviteRemove(row)"
                  >{{ translateTitle("删除") }}</span
                >
                <span v-else class="operation-ban">{{
                  translateTitle("删除")
                }}</span> -->
              </template>
            </el-table-column>

            <no-data
              slot="empty"
              v-show="!inviteCodeListLoading && inviteCodeList.length == 0"
            />
          </el-table>
        </div>
        <!-- 推荐链接结束 -->
        <!-- 邀请列表开始 -->
        <div v-show="currIndex == 3">
          <div class="friend-rebate-box">
            <div class="search-content">
              <el-input
                class="search-code"
                :clearable="true"
                v-model="friendUserId"
                maxlength="20"
                :placeholder="translateTitle('被邀请人ID')"
              ></el-input>
              <div class="twentyWid"></div>
              <el-input
                class="search-remark"
                :clearable="true"
                v-model="friendInviteCode"
                maxlength="20"
                :placeholder="translateTitle('邀请码')"
              ></el-input>
              <div class="twentyWid"></div>
              <el-date-picker
                v-model="ctimeAndEtime"
                type="daterange"
                :range-separator="translateTitle('至')"
                :start-placeholder="translateTitle('开始日期')"
                :end-placeholder="translateTitle('结束日期')"
                format="yyyy-MM-dd"
                value-format="timestamp"
              />
              <div class="twentyWid"></div>
              <span class="btn-search" @click="getFriendRebateStats">{{
                translateTitle("查询")
              }}</span>
              <div class="tenWid"></div>
              <span class="btn-reset" @click="resetFriendRebateStats">{{
                translateTitle("重置")
              }}</span>
            </div>
          </div>

          <el-table
            :data="friendRebateList"
            class="g-table"
            v-loading="friendRebateListLoading"
          >
            <el-table-column
              class="coin-cell"
              min-width="60px"
              align="left"
              prop="code"
              :label="translateTitle('被邀请人ID')"
            >
              <template slot-scope="{ row }">
                <span class="text num DINPro">{{ row.user_id }}</span>
                <i
                  class="iconfont iconicon-copy table-icon-copy"
                  v-clipboard:copy="row.user_id"
                  v-clipboard:success="copyFriendIdSuccess"
                  v-clipboard:error="copyError"
                ></i>
              </template>
            </el-table-column>
            <el-table-column
              class="coin-cell"
              min-width="40px"
              align="left"
              prop="invite_code"
              :label="translateTitle('邀请码')"
            >
              <template slot-scope="{ row }">
                <span class="text">{{ row.invite_code.toUpperCase() }}</span>
                <i
                  class="iconfont iconicon-copy table-icon-copy"
                  v-clipboard:copy="row.invite_code.toUpperCase()"
                  v-clipboard:success="copySuccess"
                  v-clipboard:error="copyError"
                ></i>
              </template>
            </el-table-column>
            <el-table-column
              class="coin-cell"
              min-width="40px"
              align="right"
              prop="total_rebate_amount"
              :label="translateTitle('团队人数')"
            >
              <template slot-scope="{ row }">
                <span class="text num DINPro">{{ row.team_user_num }}</span>
              </template>
            </el-table-column>
            <el-table-column
              class="coin-cell"
              min-width="60px"
              align="right"
              prop="remark"
              :label="translateTitle('团队交易人数')"
            >
              <template slot-scope="{ row }">
                <span class="text num DINPro">{{ row.team_trade_num }}</span>
              </template>
            </el-table-column>
            <el-table-column
              class="coin-cell"
              min-width="60px"
              align="right"
              prop="invite_user_num"
              :label="translateTitle('获得返利收入(USDT)')"
            >
              <template slot-scope="{ row }">
                <span class="text num DINPro">{{
                  row.total_rebate_amount
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              class="coin-cell"
              min-width="60px"
              align="right"
              prop="trade_user_num"
              :label="translateTitle('注册时间')"
            >
              <template slot-scope="{ row }">
                <span class="text num DINPro">{{
                  moment(row.register_time * 1000).format("YYYY-MM-DD HH:mm:ss")
                }}</span>
              </template>
            </el-table-column>

            <no-data
              slot="empty"
              v-show="!friendRebateListLoading && friendRebateList.length == 0"
            />
          </el-table>
        </div>
        <!-- 邀请列表结束 -->

        <!-- 返利记录开始 -->
        <div v-show="currIndex == 4">
          <!-- 列表 -->
          <div class="filter-box">
            <el-select
              v-model="rebate_type_list_item"
              value-key="rebate_type"
              :placeholder="translateTitle('返利类型')"
              :clearable="true"
            >
              <el-option
                v-for="item in rebate_type_list"
                :key="item.rebate_type"
                :label="item.language[`show_name_${language}`]"
                :value="item"
              >
              </el-option>
            </el-select>
            <div class="tenWid"></div>
            <el-date-picker
              v-model="ctimeAndEtime"
              type="daterange"
              :range-separator="translateTitle('至')"
              :start-placeholder="translateTitle('开始日期')"
              :end-placeholder="translateTitle('结束日期')"
              format="yyyy-MM-dd"
              value-format="timestamp"
            />

            <div class="twentyWid"></div>
            <span class="btn-search" @click="getRebateList">{{
              translateTitle("查询")
            }}</span>
            <div class="tenWid"></div>
            <span class="btn-reset" @click="resetRebate">{{
              translateTitle("重置")
            }}</span>
            <el-button
              class="btn-export"
              @click="onDownload(2)"
              :loading="exportLoading"
              ><i class="iconfont iconicon-daochu export-icon"></i
              >{{ translateTitle("导出") }}</el-button
            >
          </div>

          <el-table
            :data="rebateList"
            class="g-table"
            v-loading="rebateListLoading"
          >
            <el-table-column
              class="coin-cell"
              min-width="30px"
              align="left"
              prop="date"
              :label="translateTitle('返利时间')"
            >
              <template slot-scope="{ row }">
                <span class="text num DINPro">{{
                  moment(row.create_time * 1000).format("YYYY-MM-DD HH:mm:ss")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              class="coin-cell"
              min-width="12px"
              align="right"
              prop="rebate_amount"
              :label="translateTitle('被邀请人ID')"
            >
              <template slot-scope="{ row }">
                <span class="text num DINPro">{{
                  row.rebate_type == 1 ? row.target_user_id : "--"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              class="coin-cell"
              min-width="60px"
              align="center"
              prop="team_user_num"
              :label="translateTitle('返利类型')"
            >
              <template slot-scope="{ row }">
                <span class="text">{{ row.src_name }}</span>
              </template>
            </el-table-column>
            <el-table-column
              class="coin-cell"
              min-width="15px"
              align="right"
              prop="intvite_user_num"
              :label="translateTitle('返利数量')"
            >
              <template slot-scope="{ row }">
                <span class="text num DINPro"
                  >{{ row.rebate_number }}{{ " "
                  }}{{ row.rebate_item_name }}</span
                >
              </template>
            </el-table-column>
            <no-data
              slot="empty"
              v-show="!rebateListLoading && rebateList.length == 0"
            />
          </el-table>
        </div>
        <!-- 返利记录结束 -->
        <div class="common-table-pages">
          <p>
            {{ translateTitle("每页") }}
            <span>{{ pageInfo.pageSize }}</span>
            {{ translateTitle(`条，总共`) }}
            <span>{{ pageInfo.pageCount }}</span>
            {{ translateTitle("页") }}
          </p>
          <el-pagination
            class="mt20"
            background
            layout="prev, pager, next"
            :total="pageInfo.pageTotal"
            :page-size="pageInfo.pageSize"
            :current-page.sync="pageInfo.pageCurrent"
            @current-change="onPageChange"
          ></el-pagination>
        </div>
      </div>
    </div>
    <!-- 生成新邀请码开始 -->
    <el-dialog
      width="440px"
      :title="translateTitle('生成新邀请码')"
      custom-class="holder-detail-dialog"
      :close-on-click-modal="false"
      :before-close="onHolderDialogClose"
      :destroy-on-close="true"
      :visible.sync="detailDialogVisible"
    >
      <span class="friendInvite"
        >{{ translateTitle("好友返利比例") }}{{ " " }}
        <span class="friendInviteNum"
          >{{ digData(currRate[1] * 100) }}%</span
        ></span
      >
      <div class="rabate-rate-content">
        <ul>
          <li
            class="rabate-rate-item"
            :class="{ active: parseFloat(currRate[1]) === parseFloat(val[1]) }"
            v-for="(val, key) in rabate_rate_conf"
            :key="key"
            @click="onRateChange(val)"
          >
            {{ digData(val[1] * 100) }}%
          </li>
          <input
            type="number"
            autocomplete="off"
            v-model="custRabate"
            @input="handleCustChange()"
            @keydown="clearInput"
            :placeholder="translateTitle('自定义')"
            class="rabate-rate-item-cust"
            :class="!currRate[0] ? 'active' : ''"
          />
        </ul>
      </div>
      <p class="tips-cust">
        {{
          translateTitle("注：返利比例数值范围0-100，最小精度0.1，例如 89.5")
        }}
      </p>
      <span class="friendInvite"
        >{{ translateTitle("我的返利比例") }}{{ " "
        }}{{ digData(100 - currRate[1] * 100) }}%</span
      >
      <span class="friendInviteremark">{{ translateTitle("备注") }}</span>
      <el-input
        maxlength="20"
        v-model="inviteCodeRemark"
        :placeholder="translateTitle('备注信息')"
      ></el-input>
      <el-checkbox v-model="isDefaultCode" label="1" class="defaultCode">{{
        translateTitle("设为默认邀请码")
      }}</el-checkbox>
      <div class="button-group">
        <a class="btn-cancel" @click="onHolderDialogClose">{{
          translateTitle("取消")
        }}</a>
        <a
          class="btn-confirm"
          @click="
            createInviteCode(currRateKey, inviteCodeRemark, isDefaultCode)
          "
          >{{ translateTitle("确认") }}</a
        >
      </div>
    </el-dialog>
    <!-- 生成新邀请码结束 -->
    <!-- 修改返利设置开始 -->
    <el-dialog
      width="440px"
      :title="
        isEditCodeRemark
          ? translateTitle('修改备注')
          : translateTitle('修改返利设置')
      "
      custom-class="holder-detail-dialog"
      :close-on-click-modal="false"
      :before-close="onEditDialogClose"
      :destroy-on-close="true"
      :visible.sync="editDialogVisible"
    >
      <div v-if="!isEditCodeRemark">
        <span class="friendInvite"
          >{{ translateTitle("好友返利比例") }}{{ " " }}
          <span class="friendInviteNum"
            >{{ digData(currRate[1] * 100) }}%</span
          ></span
        >
        <div class="rabate-rate-content">
          <ul>
            <li
              class="rabate-rate-item"
              :class="{ active: parseFloat(currRate[1]) == parseFloat(val[1]) }"
              v-for="(val, key) in rabate_rate_conf"
              :key="key"
              @click="onRateChange(val)"
            >
              {{ digData(val[1] * 100) }}%
            </li>
            <input
              type="number"
              autocomplete="off"
              v-model="custRabate"
              @input="handleCustChange()"
              @keydown="clearInput"
              :placeholder="translateTitle('自定义')"
              class="rabate-rate-item-cust"
              :class="!currRate[0] ? 'active' : ''"
            />
          </ul>
        </div>
        <p class="tips-cust">
          {{
            translateTitle("注：返利比例数值范围0-100，最小精度0.1，例如 89.5")
          }}
        </p>
        <span class="friendInvite"
          >{{ translateTitle("我的返利比例") }}{{ " "
          }}{{ digData(100 - currRate[1] * 100) }}%</span
        >
      </div>
      <span
        class="friendInviteremark"
        :style="{ marginTop: isEditCodeRemark ? '0' : '15px' }"
        >{{ translateTitle("备注") }}</span
      >
      <el-input
        maxlength="20"
        v-model="inviteCodeRemark"
        :placeholder="translateTitle('备注信息')"
      ></el-input>
      <div class="button-group">
        <a class="btn-cancel" @click="onEditDialogClose">{{
          translateTitle("取消")
        }}</a>
        <a class="btn-confirm" @click="doAddOrEdit">{{
          translateTitle("确认")
        }}</a>
      </div>
    </el-dialog>
    <!-- 修改返利设置结束 -->
    <!-- 邀请对话框开始 -->
    <el-dialog
      width="600px"
      custom-class="holder-invite-dialog"
      :close-on-click-modal="false"
      :before-close="onInviteDialogClose"
      :destroy-on-close="true"
      :visible.sync="inviteDialogVisible"
    >
      <div class="swiper">
        <el-carousel
          :interval="4000"
          type="card"
          height="350px"
          :autoplay="false"
          arrow="always"
          :loop="false"
          @change="carouselChange"
        >
          <el-carousel-item v-for="(item, index) in poster_conf" :key="index">
            <img class="loop-image" :src="item.img_url" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="share-content" v-loading="shareLoading">
        <span class="shareTo">{{ translateTitle("分享到") }}</span>
        <div class="share-items">
          <ShareNetwork
            class="share-item"
            network="Telegram"
            :url="inviteObj.complieUrl"
            :title="slogan4language"
          >
            <img :src="tg" class="share-item-img" />
            <span class="share-item-text" title="Telegram">
              Telegram
            </span>
          </ShareNetwork>

          <ShareNetwork
            class="share-item"
            network="Twitter"
            :url="inviteObj.complieUrl"
            :title="slogan4language"
          >
            <img :src="tt" class="share-item-img" title="Twitter" />
            <span class="share-item-text">
              Twitter
            </span>
          </ShareNetwork>

          <ShareNetwork
            class="share-item"
            network="facebook"
            :url="inviteObj.complieUrl"
            :title="slogan4language"
          >
            <img :src="fb" class="share-item-img" title="Facebook" />
            <span class="share-item-text">
              Facebook
            </span>
          </ShareNetwork>

          <div class="share-item" @click="downloadPoster">
            <img :src="si" class="share-item-img" />
            <span class="share-item-text" :title="translateTitle('保存图片')">
              {{ translateTitle("保存图片") }}
            </span>
          </div>

          <div class="share-item" @click="copyPosterLink">
            <img :src="cl" class="share-item-img" />
            <span class="share-item-text" :title="translateTitle('复制链接')">
              {{ translateTitle("复制链接") }}
            </span>
          </div>

          <div class="share-item" @click="copyPoster">
            <img :src="ci" class="share-item-img" />
            <span class="share-item-text" :title="translateTitle('复制图片')">
              {{ translateTitle("复制图片") }}
            </span>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="translateTitle('操作提示')"
      custom-class="confirm-dialog"
      :visible.sync="confirmDialogVisible"
      width="440px"
      :lock-scroll="false"
    >
      <span style="font-size: 16px;">{{
        translateTitle("是否确认删除此邀请码？")
      }}</span>
      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="confirmDialogVisible = false">{{
          translateTitle("取消")
        }}</span>
        <span class="dialog-confirm-btn" @click="delInviteCode">{{
          translateTitle("确认")
        }}</span>
      </span>
    </el-dialog>
    <!-- 邀请对话框结束 -->
    <vue-canvas-poster
      :widthPixels="800"
      :painting="painting"
      @success="posterSuccess"
      @fail="posterFail"
    ></vue-canvas-poster>
  </div>
</template>
<script>
import NoData from "@/components/common/NoData";
import globalFun from "@/assets/js/globalFun.js";
import BigNumber from "bignumber.js";
import * as Account from "@/model/user/account";
import {
  apiGetRebateCommonInfo,
  apiGetTotalRebateStats,
  apiGetInviteCodeStats,
  apiGetFriendRebateStats,
  apiGetRebateList,
  apiCommonExport,
  apiUpdateInviteCode,
  apiCreateInviteCode,
  apiAppIntialDataV2,
  apiGetInviteUrl,
  apiDelInviteCode,
} from "@/model/ucenter/spreadCenter";
import {
  apiGetStaticTableInfo,
  getCacheStaticTableInfo,
} from "@/model/settings";
import commonMixin from "@/components/common/commonMixin.vue";
import { showMessage } from "@/components/dialog/message";
import { mapActions, mapGetters } from "vuex";
import { translateTitle } from "@/assets/js/i18n";

import moment from "moment";
import { VueCanvasPoster } from "vue-canvas-poster";

import ci from "@/assets/img/shareInvite/ci.png";
import cl from "@/assets/img/shareInvite/cl.png";
import fb from "@/assets/img/shareInvite/fb.png";
import si from "@/assets/img/shareInvite/si.png";
import tg from "@/assets/img/shareInvite/tg.png";
import tt from "@/assets/img/shareInvite/tt.png";

import logo8coin from "@/assets/img/siteLogo/8coin.png";
import b9Logo from "@/assets/img/siteLogo/b9.png";
import bxcLogo from "@/assets/img/siteLogo/bxc.png";
import hxLogo from "@/assets/img/siteLogo/hx.png";
import Logo100 from "@/assets/img/siteLogo/logo100.png";
import mask from "@/assets/img/siteLogo/mask.png";
import everTrust from "@/assets/img/siteLogo/everTrust.png";
import eightV from "@/assets/img/siteLogo/8v.png";
import UEEx from "@/assets/img/siteLogo/ueasy.png";
import Ahex from "@/assets/img/siteLogo/Ahex.png";
import logo5A from "@/assets/img/siteLogo/logo5A.png";
import logobitda from "@/assets/img/siteLogo/logobitda.png";
import logoS6 from "@/assets/img/siteLogo/logoS6.png";
import { VUE_APP_ID, VUE_UNION_STATION } from "@/assets/js/stationSetting";
import routeSplicing from "@/assets/js/routeSplicing";
import { number } from "echarts";

export default {
  mixins: [commonMixin],
  data() {
    return {
      moment,
      ci,
      cl,
      fb,
      si,
      tg,
      tt,
      mask,
      everTrust,
      eightV,
      UEEx,
      Ahex,
      logo5A,
      logobitda,
      logoS6,
      Logo100,
      b9Logo,
      logo8coin,
      bxcLogo,
      hxLogo,
      avatar: "",
      userInfo: {},
      nick_name: "",
      posterSrc: "",
      painting: {},
      symbolObj: {},
      currIndex: 1,
      item_name: "",
      assetsShow: "",
      dataList: [],
      totalRebateList: [],
      inviteCodeList: [],
      friendRebateList: [],
      rebateList: [],
      tabList: [
        { label: "数据总览", value: 1 },
        { label: "我的邀请码", value: 2 },
        { label: "好友列表", value: 3 },
        { label: "我的返利", value: 4 },
        // { label: "推荐链接", value: 2 },
        // { label: "邀请列表", value: 3 },
        // { label: "返利记录", value: 4 },
      ],
      tableLoading: false,
      totalRebateListLoading: false,
      inviteCodeListLoading: false,
      friendRebateListLoading: false,
      rebateListLoading: false,
      exportLoading: false,
      shareLoading: false,
      inviteImgLoading: false,
      shareImageType: "",
      detailDialogVisible: false,
      editDialogVisible: false,
      inviteDialogVisible: false,
      isDefaultCode: false,
      inviteCodeRemark: "",
      currRate: 0,
      currRateKey: 0,
      initInviteStatus: 0,
      isEditCodeRemark: true, //只编辑邀请码备注
      searchCode: "",
      searchRemark: "",
      friendUserId: "",
      friendInviteCode: "",
      projectFullName: "",
      slogan: "",
      projectTitle: "",
      projectLogo: "",
      inviteObj: {},
      editCode: "",
      max_rebate_rate: "",
      poster_conf: [],
      default_invite_code: {},
      rabate_rate_conf: {},
      total_stats: {},
      centerHost: "",
      webHost: "",
      invite_url: "",
      confirmDialogVisible: false,
      delPreInviteCode: "",
      // invite_link_ellipsis: "",
      pageInfo: {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1,
      },
      rebate_type_list_all: [], //数据总览统计静态表
      rebate_type_list: [], //数据总览统计静态表is_show
      rebate_type_list_item: {}, //数据总览统交易类型对象
      all_search_id: "", //所有返利类型
      ctimeAndEtime: "",
      total_stats_son: {
        total_rebate_amount: "0.00", //累计返佣
        total_invite_user_num: 0, //累计邀请好友
        total_trade_user_num: 0, //累计好友交易人数
      },
      globalFun,
      custRabate: "",
      isUeex: false,
      rebate_mode: 1,
      self_real_rebate_rate: "--",
      target_real_rebate_rate: "--",
    };
  },
  components: {
    NoData,
    VueCanvasPoster,
  },
  async created() {
    this.webHost =
      window.location.protocol +
      "//" +
      window.location.host +
      this.routeSplicing(this.language, "register");
    this.getAppIntialDataV2();
    this.getStaticTableInfo(13);
    this.getStaticTableInfo(55);
    this.getRebateCommonInfo();
    await this.getStaticTableInfo(59);
    this.getTotalRebateStats();
    this.getUserInfoInit();
    if (
      VUE_APP_ID == "UhkNBRbpeSGTuaf0yuiG1Yin" ||
      VUE_APP_ID == "OCzy5RbpeSGTuaf0yuiG1Yin"
    ) {
      this.projectFullName = "Bullxcow";
      this.projectLogo = this.bxcLogo;
    } else if (VUE_APP_ID == "9wZvazddcsWrnWtnB7Zhy399") {
      this.projectFullName = "币久";
      this.projectLogo = this.b9Logo;
    } else if (VUE_UNION_STATION == "100Ex") {
      this.projectFullName = "100Ex";
      this.projectLogo = this.Logo100;
    } else if (VUE_UNION_STATION == "MaskEx") {
      this.projectFullName = "MaskEx";
      this.projectLogo = this.mask;
    } else if (VUE_UNION_STATION == "EverTrust") {
      this.projectFullName = "恒信";
      this.projectLogo = this.everTrust;
    } else if (VUE_UNION_STATION == "8V") {
      this.projectFullName = "8V";
      this.projectLogo = this.eightV;
    } else if (VUE_UNION_STATION == "UEEx") {
      this.projectFullName = "UEEx";
      this.projectLogo = this.UEEx;
    } else if (VUE_UNION_STATION == "Ahex") {
      this.projectFullName = "Ahex";
      this.projectLogo = this.Ahex;
    } else if (VUE_UNION_STATION == "5A") {
      this.projectFullName = "5A";
      this.projectLogo = this.logo5A;
    } else if (VUE_UNION_STATION == "bitda") {
      this.projectFullName = "bitda";
      this.projectLogo = this.logobitda;
    } else if (VUE_UNION_STATION == "S6") {
      this.projectFullName = "S6";
      this.projectLogo = this.logoS6;
    } else if (VUE_UNION_STATION == "8coin") {
      this.projectFullName = "8coin";
      this.sloganColor = "#3960fb";
      this.projectLogo = this.logo8coin;
    } else {
      this.projectFullName = "汇信";
      this.projectLogo = this.hxLogo;
    }
  },

  computed: {
    ...mapGetters({
      // userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency",
    }),
    slogan4language() {
      if (VUE_UNION_STATION == "bitda") return "";
      return "高收益，上" + this.projectFullName;
    },
    myCurrRate() {
      return new BigNumber().toNumber();
    },
  },

  methods: {
    routeSplicing,
    translateTitle,
    clearInput(e) {
      let key = e.key;
      if (key === "e" || key === "E" || key === "+" || key === "-") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    handleCustChange() {
      this.currRate = [null, null];
      if (this.custRabate === "") {
        return;
      } else {
      }
      let reg = /(^(\d|[1-9]\d)(\.\d{1,1})?$)|(^100$)/;
      if (!reg.test(parseFloat(this.custRabate))) {
        if (this.custRabate > 100) {
          this.custRabate = 100;
        } else if (this.custRabate < 0) {
          this.custRabate = "";
        } else {
          this.custRabate = (Math.floor(this.custRabate * 10) / 10).toFixed(1);
        }
      }
      this.currRate = [
        null,
        new BigNumber(this.custRabate).div(100).toNumber(),
      ];
    },
    //精度优化
    digData(data) {
      return this.globalFun.handleCutZero(parseFloat(data).toFixed(10));
    },
    //幻灯片切换
    carouselChange(index) {
      this.initInviteStatus = index;
    },
    //邀请图片加载完成
    inviteImgLoaded() {
      this.inviteImgLoading = false;
    },
    // 获取用户信息
    async getUserInfoInit() {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      const { userInfo } = this.userInfo;
      if (userInfo) {
        const { user_id, nick_name, avatar } = userInfo;
        this.nick_name =
          nick_name ||
          this.userInfo.accountInfo.username ||
          this.globalFun.userDecryption(
            this.userInfo.accountInfo.mobile,
            "mobile"
          ) ||
          this.globalFun.userDecryption(userInfo.accountInfo.email, "email") ||
          this.translateTitle(`用户`) + `${user_id}`;
        this.avatar = avatar ? avatar : "https://";
      }
    },
    //左移滚动条
    inviteImgScrollLeft() {
      let moveWidthNormal = 222 + 10 * 2;
      this.$refs.inviteImgPosition.scrollLeft =
        this.$refs.inviteImgPosition.scrollLeft + moveWidthNormal;
      if (this.initInviteStatus < this.poster_conf.length - 1) {
        this.initInviteStatus++;
      }
    },
    //右移滚动条
    inviteImgScrollRight() {
      let moveWidthNormal = 222 + 10 * 2;
      this.$refs.inviteImgPosition.scrollLeft =
        this.$refs.inviteImgPosition.scrollLeft - moveWidthNormal;
      if (this.initInviteStatus > 0) {
        this.initInviteStatus--;
      }
    },
    //Base64 To Blob
    convertBase64ToBlob(base64, type) {
      var imgs = base64.replace(/^data:image\/\w+;base64,/, ""); //去掉base64位头部
      var bytes = window.atob(imgs);
      var ab = new ArrayBuffer(bytes.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ab], { type: type });
    },

    //写入剪切板
    async writeClipboard(src) {
      try {
        const blob = this.convertBase64ToBlob(src, "image/png");
        await navigator.clipboard.write([
          new ClipboardItem({
            [blob.type]: blob,
          }),
        ]);
      } catch (err) {
        console.error(err.name, err.message);
      }
    },

    //图片生成成功回调函数
    posterSuccess(src) {
      this.shareLoading = false;
      if (this.shareImageType == "copy") {
        this.writeClipboard(src);
        this.copySuccess();
      } else if (this.shareImageType == "download") {
        const a = document.createElement("a");
        a.href = src;
        a.setAttribute("download", "chart-download");
        a.click();
        showMessage({
          message: this.translateTitle(`图片下载成功`),
          type: "success",
        });
      }
    },
    //图片生成失败回调函数
    posterFail(err) {
      console.log("fail", err);
    },
    //复制下载链接
    copyPosterLink() {
      let that = this;
      this.$copyText(this.inviteObj.complieUrl).then(
        function(e) {
          that.copySuccess();
        },
        function(e) {
          console.log(e);
        }
      );
    },
    // 下载海报
    downloadPoster() {
      this.shareImageType = "download";
      this.shareLoading = true;
      this.painting = JSON.parse(JSON.stringify(this.poster()));
    },
    // 复制海报
    copyPoster() {
      this.shareImageType = "copy";
      this.shareLoading = true;
      this.painting = JSON.parse(JSON.stringify(this.poster()));
    },
    poster() {
      return this.inviteObj.rebate_mode == 1
        ? {
            width: "222px",
            height: "370px",
            background: "#fff",
            views: [
              {
                type: "image",
                url:
                  this.poster_conf[this.initInviteStatus].img_url +
                  "?r=" +
                  this.moment().unix(),
                css: {
                  top: "0px",
                  left: "0px",
                  width: "222px",
                  height: "310px",
                },
              },
              {
                type: "image",
                url: this.projectLogo,
                css: {
                  top: "320px",
                  left: "5px",
                  width: "35px",
                  height: "35px",
                  borderRadius: "100%",
                },
              },
              {
                type: "text",
                text: this.projectFullName,
                css: {
                  top: "329px",
                  left: "45px",
                  width: "150px",
                  maxLines: 1,
                  fontSize: "13px",
                  fontWeight: "500",
                },
              },
              {
                type: "text",
                // text: "注册邀请码: " + this.inviteObj.code,
                text:
                  this.translateTitle(`邀请码`) + ": " + this.inviteObj.code,
                css: {
                  top: "348px",
                  left: "45px",
                  width: "150px",
                  maxLines: 1,
                  fontSize: "9px",
                  fontWeight: "400",
                  color: "#354052",
                },
              },
              {
                type: "qrcode",
                content: this.inviteObj.complieUrl,
                css: {
                  top: "327px",
                  right: "10px",
                  color: "#000",
                  width: "31px",
                  height: "31px",
                },
              },
            ],
          }
        : {
            width: "222px",
            height: "370px",
            background: "#fff",
            views: [
              {
                type: "image",
                url:
                  this.poster_conf[this.initInviteStatus].img_url +
                  "?r=" +
                  this.moment().unix(),
                css: {
                  top: "0px",
                  left: "0px",
                  width: "222px",
                  height: "310px",
                },
              },
              {
                type: "image",
                url: this.projectLogo,
                css: {
                  top: "320px",
                  left: "5px",
                  width: "35px",
                  height: "35px",
                  borderRadius: "100%",
                },
              },
              {
                type: "text",
                text: this.projectFullName,
                css: {
                  top: "329px",
                  left: "45px",
                  width: "150px",
                  maxLines: 1,
                  fontSize: "13px",
                  fontWeight: "500",
                },
              },
              {
                type: "text",
                text: this.translateTitle(`好友实际返利`),
                css: {
                  top: "330px",
                  left: "122px",
                  width: "150px",
                  maxLines: 1,
                  fontSize: "8px",
                  fontWeight: "400",
                },
              },
              {
                type: "text",
                text: this.inviteObj.target_real_rebate_rate,
                css: {
                  top: "343px",
                  left: "135px",
                  width: "150px",
                  maxLines: 1,
                  fontSize: "8px",
                  fontWeight: "500",
                  color: "#f04a5a",
                },
              },
              {
                type: "text",
                // text: "注册邀请码: " + this.inviteObj.code,
                text:
                  this.translateTitle(`邀请码`) + ": " + this.inviteObj.code,
                css: {
                  top: "348px",
                  left: "45px",
                  width: "150px",
                  maxLines: 1,
                  fontSize: "9px",
                  fontWeight: "400",
                  color: "#354052",
                },
              },
              {
                type: "qrcode",
                content: this.inviteObj.complieUrl,
                css: {
                  top: "327px",
                  right: "10px",
                  color: "#000",
                  width: "31px",
                  height: "31px",
                },
              },
            ],
          };
    },
    onRateChange(val) {
      this.currRate = val;
      this.currRateKey = val[0];
    },
    onAreaChange(item) {
      this.currIndex = item.value;
      this.pageInfo = {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1,
      };
      if (this.currIndex == 1) {
        this.resetTotalRebate();
      }
      if (this.currIndex == 2) {
        this.resetInviteCodeStats();
      }
      if (this.currIndex == 3) {
        this.resetFriendRebateStats();
      }
      if (this.currIndex == 4) {
        this.resetRebate();
      }
    },
    //关闭生成邀请码弹窗
    onHolderDialogClose() {
      this.detailDialogVisible = false;
    },
    //关闭编辑邀请码弹窗
    onEditDialogClose() {
      this.editDialogVisible = false;
    },
    //开启生成邀请码弹窗
    onHolderDialogOpen() {
      this.detailDialogVisible = true;
      this.inviteCodeRemark = "";
      this.currRate = this.rabate_rate_conf[0];
      this.currRateKey = this.rabate_rate_conf[0][0];
      this.custRabate = "";
      this.isDefaultCode = false;
    },
    //开启编辑邀请码弹窗
    onEditDialogOpen(obj, isEditCodeRemark) {
      this.editDialogVisible = true;
      this.isEditCodeRemark = isEditCodeRemark;
      this.editCode = obj.code;
      this.onRateChange([obj.srrc_id, obj.target_rebate_rate]);
      this.custRabate = this.digData(this.currRate[1] * 100);
      this.inviteCodeRemark = obj.remark;
    },
    //关闭邀请分享弹窗
    onInviteDialogClose() {
      this.shareImageType = "";
      this.inviteDialogVisible = false;
      this.initInviteStatus = 0;
    },
    //开启邀请分享弹窗
    onInviteDialogOpen(obj) {
      this.inviteObj = obj;
      this.inviteObj.complieUrl = this.centerHost + this.inviteObj.invite_url;
      this.inviteDialogVisible = true;
    },
    //确认删除邀请码
    onInviteRemove(row) {
      this.confirmDialogVisible = true;
      this.delPreInviteCode = row.code;
    },
    //删除邀请码 apiDelInviteCode
    async delInviteCode() {
      this.confirmDialogVisible = false;
      let params = {
        invite_code: this.delPreInviteCode,
      };
      const { status, data } = await apiDelInviteCode(params);
      if (status == 200) {
        this.$message({
          message: this.translateTitle(`删除成功`),
          type: "success",
        });
        this.getInviteCodeStats();
      } else {
        this.$message({
          message: this.translateTitle(`删除失败`),
          type: "error",
        });
      }
    },
    //判断是新增邀请码或修改邀请码备注
    doAddOrEdit() {
      if (
        (this.currRateKey != this.default_invite_code.srrc_id &&
          !this.isEditCodeRemark) ||
        Number(this.default_invite_code.target_rebate_rate) !=
          Number(this.currRate[1])
      ) {
        this.createInviteCode(this.currRateKey, this.inviteCodeRemark, true);
      } else {
        this.editInviteCode();
      }
    },
    // 好友ID复制成功
    copyFriendIdSuccess() {
      showMessage({
        message: this.translateTitle(`复制成功`),
        type: "success",
      });
    },
    // 复制成功
    copySuccess() {
      showMessage({
        message: this.translateTitle(`复制成功，快分享给好友吧`),
        type: "success",
      });
    },
    // 复制失败
    copyError() {
      showMessage({ message: this.translateTitle(`内容复制失败`) });
    },
    async getStaticTableInfo(sv_id) {
      const { status, data } = await apiGetStaticTableInfo(sv_id);
      if (status == 200) {
        if (sv_id == 13) {
          this.symbolObj = JSON.parse(JSON.stringify(data));
        }
        if (sv_id == 55) {
          this.symbolList = JSON.parse(JSON.stringify(data));
          for (let item in this.coinData) {
            this.coinData[item].is_trade = false;
            for (let ele in this.symbolList) {
              if (
                this.coinData[item].item_id == this.symbolList[ele].coin_from
              ) {
                this.coinData[item].is_trade = true;
              }
            }
          }
        }
        if (sv_id == 59) {
          let searchArray = [];
          this.rebate_type_list_all = JSON.parse(JSON.stringify(data));
          for (let key in this.rebate_type_list_all) {
            if (this.rebate_type_list_all[key].is_show == 1) {
              this.rebate_type_list.push(this.rebate_type_list_all[key]);
              searchArray.push(this.rebate_type_list_all[key].search_id);
            }
          }
          this.all_search_id = searchArray.join(",");
          this.rebate_type_list_item = {};
        }
      }
    },

    // 重置数据总览搜索条件
    resetTotalRebate() {
      this.ctimeAndEtime = [];
      this.rebate_type_list_item = {};
      this.totalRebateList = [];
      this.total_stats_son = {};
      this.pageInfo = {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1,
      };
      this.getTotalRebateStats();
    },
    // 重置我的邀请码搜索条件
    resetInviteCodeStats() {
      this.searchCode = "";
      this.searchRemark = "";
      this.pageInfo = {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1,
      };
      this.getInviteCodeStats();
    },
    // 重置好友列表搜索条件
    resetFriendRebateStats() {
      this.friendInviteCode = "";
      this.friendUserId = "";
      this.ctimeAndEtime = [];
      this.pageInfo = {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1,
      };
      this.getFriendRebateStats();
    },
    // 重置返利记录搜索条件
    resetRebate() {
      this.ctimeAndEtime = [];
      this.rebate_type_list_item = {};
      this.rebateList = [];
      this.pageInfo = {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1,
      };
      // this.rebate_type_list_item = this.rebate_type_list[0];
      // this.rebate_type_list_item = {};
      this.getRebateList();
    },
    //获取数据总览统计信息
    async getTotalRebateStats(pageNum = 1) {
      let create_ctime = "";
      let create_etime = "";
      if (this.ctimeAndEtime) {
        const [start, end] = this.ctimeAndEtime;
        const end2 = end + 86399000;
        create_ctime = parseInt(start / 1000);
        create_etime = parseInt(end2 / 1000);
      }
      let params = {
        rebate_type_list: this.rebate_type_list_item
          ? this.rebate_type_list_item.search_id
            ? this.rebate_type_list_item.search_id
            : this.all_search_id
          : this.all_search_id,
        ctime: create_ctime ? create_ctime : "",
        etime: create_etime ? create_etime : "",
        page: !isNaN(pageNum) ? pageNum : 1,
        page_size: this.pageInfo.pageSize ? this.pageInfo.pageSize : 10,
      };
      this.totalRebateListLoading = true;
      const { status, data } = await apiGetTotalRebateStats(params);
      this.totalRebateListLoading = false;
      if (status == 200) {
        this.totalRebateList = JSON.parse(JSON.stringify(data.list));
        this.total_stats_son = JSON.parse(JSON.stringify(data.total_stats));
        this.pageInfo = JSON.parse(JSON.stringify(data.pageInfo));
      }
    },
    //获取返利记录
    async getRebateList(pageNum = 1) {
      let create_ctime = "";
      let create_etime = "";
      if (this.ctimeAndEtime) {
        const [start, end] = this.ctimeAndEtime;
        const end2 = end + 86399000;
        create_ctime = parseInt(start / 1000);
        create_etime = parseInt(end2 / 1000);
      }
      let params = {
        rebate_type_list: this.rebate_type_list_item
          ? this.rebate_type_list_item.search_id
            ? this.rebate_type_list_item.search_id
            : this.all_search_id
          : this.all_search_id,
        ctime: create_ctime ? create_ctime : "",
        etime: create_etime ? create_etime : "",
        page: !isNaN(pageNum) ? pageNum : 1,
        page_size: this.pageInfo.pageSize ? this.pageInfo.pageSize : 10,
      };
      this.rebateListLoading = true;
      const { status, data } = await apiGetRebateList(params);
      this.rebateListLoading = false;
      if (status == 200) {
        let tempArray = [];
        // this.rebateList = JSON.parse(JSON.stringify(data.list));
        tempArray = JSON.parse(JSON.stringify(data.list));
        this.pageInfo = JSON.parse(JSON.stringify(data.pageInfo));
        tempArray.forEach(ele => {
          for (const key in this.symbolObj) {
            if (ele.rebate_item_id == key) {
              ele.rebate_item_name = this.symbolObj[
                key
              ].item_name.toUpperCase();
            }
          }
          for (const key in this.rebate_type_list_all) {
            if (ele.src_id == this.rebate_type_list_all[key].rebate_type) {
              ele.src_name = this.rebate_type_list_all[key].language[
                `client_remark_${this.language}`
              ];
            }
          }
        });
        this.rebateList = tempArray;
      }
    },
    //获取推广链接返佣统计
    async getInviteCodeStats(pageNum = 1) {
      let params = {
        page: !isNaN(pageNum) ? pageNum : 1,
        page_size: this.pageInfo.pageSize ? this.pageInfo.pageSize : 10,
        code: this.searchCode ? this.searchCode : "",
        remark: this.searchRemark ? this.searchRemark : "",
      };
      this.inviteCodeListLoading = true;
      const { status, data } = await apiGetInviteCodeStats(params);
      this.inviteCodeListLoading = false;
      if (status == 200) {
        this.inviteCodeList = JSON.parse(JSON.stringify(data.list));
        this.pageInfo = JSON.parse(JSON.stringify(data.pageInfo));
      }
    },

    //获取邀请好友返佣统计
    async getFriendRebateStats(pageNum = 1) {
      let create_ctime = "";
      let create_etime = "";
      if (this.ctimeAndEtime) {
        const [start, end] = this.ctimeAndEtime;
        const end2 = end + 86399000;
        create_ctime = parseInt(start / 1000);
        create_etime = parseInt(end2 / 1000);
      }
      let params = {
        code: this.friendInviteCode ? this.friendInviteCode : "",
        rcmd_user_id: this.friendUserId ? this.friendUserId : "",
        page: !isNaN(pageNum) ? pageNum : 1,
        page_size: this.pageInfo.pageSize ? this.pageInfo.pageSize : 10,
        ctime: create_ctime ? create_ctime : "",
        etime: create_etime ? create_etime : "",
      };
      this.friendRebateListLoading = true;
      const { status, data } = await apiGetFriendRebateStats(params);
      this.friendRebateListLoading = false;
      if (status == 200) {
        this.friendRebateList = JSON.parse(JSON.stringify(data.list));
        this.pageInfo = JSON.parse(JSON.stringify(data.pageInfo));
      }
    },

    //编辑邀请码
    async editInviteCode() {
      let params = {
        invite_code: this.editCode,
        remark: this.inviteCodeRemark,
        // srrc_id: this.currRateKey,
      };
      const { status, data } = await apiUpdateInviteCode(params);
      this.onEditDialogClose();
      if (status == 200 && data) {
        showMessage({
          message: this.translateTitle(`编辑成功`),
          type: "success",
        });
        this.getInviteCodeStats();
        this.getRebateCommonInfo();
      } else {
        showMessage({
          message: this.translateTitle(`编辑失败`),
        });
      }
    },
    //设置默认邀请码
    async setDefaultInviteCode(invite_code) {
      let params = {
        invite_code: invite_code,
        is_default: 2,
      };
      const { status, data } = await apiUpdateInviteCode(params);
      if (status == 200 && data) {
        showMessage({
          message: this.translateTitle(`设为默认成功`),
          type: "success",
        });
        this.getInviteCodeStats();
        this.getRebateCommonInfo();
      } else {
        showMessage({
          message: this.translateTitle(`设为默认失败`),
        });
      }
    },
    //生成邀请码
    async createInviteCode(currRateKey, inviteCodeRemark, isDefaultCode) {
      if (!inviteCodeRemark.trim()) {
        showMessage({
          message: this.translateTitle(`请输入备注`),
        });
        return;
      }
      if (!this.currRate[0] && !this.currRate[1] && this.currRate[1] !== 0) {
        showMessage({
          message: this.translateTitle(`请选择返利比例或填写自定义比例数值`),
        });
        return;
      }
      let params = {};
      if (this.currRate[0]) {
        params = {
          srrc_id: currRateKey,
          remark: inviteCodeRemark,
          is_default: isDefaultCode ? 2 : 1,
        };
      } else {
        params = {
          target_rate: this.currRate[1],
          remark: inviteCodeRemark,
          is_default: isDefaultCode ? 2 : 1,
        };
      }
      const { status, data } = await apiCreateInviteCode(params);
      this.onHolderDialogClose();
      this.onEditDialogClose();
      if (status == 200 && data) {
        if (
          this.currRateKey != this.default_invite_code.srrc_id &&
          !this.isEditCodeRemark
        ) {
          showMessage({
            message: this.translateTitle(`编辑成功,已新增邀请码`),
            type: "success",
          });
        } else {
          showMessage({
            message: this.translateTitle(`生成邀请码成功`),
            type: "success",
          });
        }

        this.getInviteCodeStats();
        this.getRebateCommonInfo();
      } else {
        showMessage({
          message: this.translateTitle(`生成邀请码失败`),
        });
      }
    },
    //通过APP初始化API获取邀请链接
    getAppIntialDataV2() {
      apiGetInviteUrl().then(res => {
        if (res.status == 200) {
          this.centerHost = res.data.invite_url;
        }
      });
    },

    getRebateCommonInfo() {
      apiGetRebateCommonInfo().then(res => {
        if (res.status == 200) {
          this.default_invite_code = res.data.default_invite_code;
          this.max_rebate_rate = res.data.max_rebate_rate;
          this.inviteImgLoading = true;
          this.poster_conf = res.data.poster_conf;
          // this.rabate_rate_conf = res.data.rabate_rate_conf;
          let rabate_rate_conf_temp = res.data.rabate_rate_conf;
          let rabate_rate_conf_array = [];
          for (let key in rabate_rate_conf_temp) {
            rabate_rate_conf_array.push([key, rabate_rate_conf_temp[key]]);
          }
          rabate_rate_conf_array.sort((a, b) => {
            return a[1] - b[1];
          });
          this.rabate_rate_conf = rabate_rate_conf_array;
          this.total_stats = res.data.total_stats;
          this.invite_url = res.data.default_invite_code.invite_url;
          this.rebate_mode = res.data.default_invite_code.rebate_mode;
          this.self_real_rebate_rate =
            res.data.default_invite_code.self_real_rebate_rate;
          this.target_real_rebate_rate =
            res.data.default_invite_code.target_real_rebate_rate;
          // this.invite_link_ellipsis = this.centerHost + this.invite_url;
          // if (this.invite_link_ellipsis.length > 49) {
          //   this.invite_link_ellipsis =
          //     this.invite_link_ellipsis.slice(0, 10) + "...";
          // }
          this.currRate = this.rabate_rate_conf[0];
        }
      });
    },
    //页数变更
    onPageChange(val) {
      if (this.currIndex == 1) {
        this.getTotalRebateStats(val);
      }
      if (this.currIndex == 2) {
        this.getInviteCodeStats(val);
      }
      if (this.currIndex == 3) {
        this.getFriendRebateStats(val);
      }
      if (this.currIndex == 4) {
        this.getRebateList(val);
      }
    },
    //导出
    async onDownload(type) {
      let create_ctime = "";
      let create_etime = "";
      if (this.ctimeAndEtime) {
        const [start, end] = this.ctimeAndEtime;
        const end2 = end + 86399000;
        create_ctime = parseInt(start / 1000);
        create_etime = parseInt(end2 / 1000);
      }
      let params = {
        rebate_type_list: this.rebate_type_list_item
          ? this.rebate_type_list_item.search_id
            ? this.rebate_type_list_item.search_id
            : this.all_search_id
          : this.all_search_id,
        ctime: create_ctime ? create_ctime : "",
        etime: create_etime ? create_etime : "",
        type: type,
      };
      this.exportLoading = true;
      const result = await apiCommonExport(params);
      this.exportLoading = false;
      if (result.status === 200) {
        this.saveFile(result.data, result.status, result.msg);
      }
    },
    // 下载
    saveFile(data, status, msg) {
      if (status !== 200) {
        this.$message(msg ? msg : status);
        return;
      }
      let link = document.createElement("a");
      link.href = data.url;
      link.download = "";
      link.click();
    },
  },
};
</script>
<style lang="scss">
.uc-spread-center {
  .el-carousel {
    width: 100%;
    height: 100%;
    .el-carousel__item {
      text-align: center;
      .loop-image {
        width: 250px;
        height: 350px;
        border-radius: 7.4px;
      }
    }

    .el-carousel__item:nth-child(2n) {
      background-color: transparent;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: transparent;
    }
    .el-carousel__indicators--outside {
      display: none;
    }
  }

  .holder-invite-dialog {
    background-color: #f3f7fc;
    .share-content {
      background-color: #fff;
      width: 600px;
      height: 150px;
      margin: 20px -20px -30px -20px;
      padding: 15px 20px 25px 20px;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      .shareTo {
        font-size: 14px;
        text-align: center;
        color: #354052;
      }
      .share-items {
        width: 100%;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .share-item {
          height: 77px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin-right: 13px;
          cursor: pointer;
          .share-item-img {
            height: 50px;
            width: 50px;
            margin-bottom: 10px;
          }
          .share-item-text {
            font-size: 12px;
            text-align: center;
            color: #354052;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 80px;
          }
        }
      }
    }
    .swiper {
      width: 600px;
      /* height: 310px; 旧轮播图 */
      height: 380px;
      background-color: #f3f7fc;
      margin: 0 -20px 0 -20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      position: relative;
      .placeholderDiv {
        height: 280px;
        width: 120px;
        margin: 0 10px;
        border-radius: 7.4px;
        flex-grow: 0;
        flex-shrink: 0;
      }
      .grey-image {
        height: 280px;
        width: 200px;
        margin: 0 10px;
        border-radius: 7.4px;
        flex-grow: 0;
        flex-shrink: 0;
        filter: opacity(0.45);
      }
      .activeImage {
        height: 310px;
        width: 222px;
        border-radius: 9px;
        flex-grow: 0;
        flex-shrink: 0;
        filter: opacity(1);
        /* transition: height 0.5s; */
      }
      .pointerLeft {
        position: fixed;
        /* maring-top: 143px; */
        margin-left: 430px;
        cursor: pointer;
      }
      .pointerRight {
        position: fixed;
        /* maring-top: 143px; */
        margin-left: 30px;
        transform: rotate(180deg);
        cursor: pointer;
      }
    }
  }

  .holder-detail-dialog {
    border-radius: 8px;
    .el-dialog__header {
      font-size: 20px;
      font-weight: 500;
      padding: 20px 30px 0;
    }
    .el-dialog__body {
      padding: 20px 30px 30px;
    }
    .el-checkbox__inner {
      border-radius: 50%;
    }
    .button-group {
      margin: 25px 25px 0 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn-cancel {
        width: 160px;
        height: 40px;
        border-radius: 3px;
        border: solid 1px #c2c7d0;
        font-size: 14px;
        text-align: center;
        color: #354052;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .btn-confirm {
        width: 160px;
        height: 40px;
        border-radius: 3px;
        background-image: linear-gradient(73deg, #c61b4e 21%, #ee244c 82%);
        font-size: 14px;
        text-align: center;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
    .defaultCode {
      margin-top: 14px;
    }
    .friendInviteremark {
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      color: #354052;
      display: inline-block;
      margin-top: 15px;
      margin-bottom: 5px;
    }
    .friendInvite {
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      color: #354052;
      .friendInviteNum {
        color: #c61b3d;
      }
    }
    .tips-cust {
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.93;
      color: #c2c7d0;
    }
    .rabate-rate-content {
      width: 100%;
      margin-top: 16px;
      // margin-bottom: 6px;
      ul {
        display: flex;
        flex-wrap: wrap;
        .rabate-rate-item {
          cursor: pointer;
          width: 80px;
          height: 30px;
          background-color: #f1f5f9;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          font-weight: 500;
          color: #354052;
          margin-right: 10px;
          margin-bottom: 10px;
          border-radius: 4px;
          position: relative;
          &.active {
            border: solid 1px #c61b3d;
            background-color: #fff;
            color: #c61b3d;
            box-sizing: border-box;
          }
        }
        .rabate-rate-item-cust::-webkit-inner-spin-button,
        .rabate-rate-item-cust::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        .rabate-rate-item-cust {
          -moz-appearance: textfield;
        }
        .rabate-rate-item-cust {
          width: 80px;
          height: 30px;
          text-align: center;
          border-radius: 4px;
          border: solid 1px #c2c7d0;
          font-size: 15px;
          font-weight: 500;
          &:focus {
            border: solid 1px;
            background-color: #fff;
            color: #c61b3d;
            box-sizing: border-box;
            border-color: #c61b3d !important;
          }
          &.active {
            border: solid 1px;
            background-color: #fff;
            color: #c61b3d;
            box-sizing: border-box;
            border-color: #c61b3d !important;
          }
          &::placeholder {
            color: #c2c7d0;
            font-weight: normal;
          }
        }
      }
    }
  }
  .trade-container {
    .title-content {
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 0;
      .left-content {
        width: 60%;
        margin-right: 20px;
        height: 165px;
        border-radius: 4px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
        background: #fff;
        display: flex;
        justify-content: space-between;
        min-width: 731px;
        .left-img {
          // width: 218px;
          min-width: 230px;
          padding: 20px 40px 24px 40px;
          border-right: 1px solid #ecedf1;
          .nick-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 144px;
            color: #354052;
            padding-bottom: 7px;
            font-size: 18px;
            margin-top: 10px;
          }
          img {
            width: 48px;
            height: 48px;
            margin-bottom: 15px;
          }
          p {
            margin-bottom: 0px;
            color: #354052;
            font-size: 18px;
            font-weight: 500;
          }
          .uid {
            font-size: 14px;
            font-weight: 500;
            color: #354052;
          }
          .el-avatar {
            user-select: none;
            img {
              width: 48px;
              height: 48px;
            }
          }
        }
        .left-text {
          width: 100%;
          color: #354052;
          padding: 20px 40px 24px 40px;
          font-weight: 500;
          .left-text-title {
            font-size: 20px;
            margin-bottom: 15px;
            user-select: none;
          }
          .left-content-item {
            width: 33%;
            display: inline-block;
            .one-item {
              font-size: 14px;
              user-select: none;
            }
            .two-item {
              font-size: 12px;
              span {
                font-size: 24px;
                margin-right: 5px;
              }
            }
            .three-item {
              span.num {
                display: block;
                width: fit-content;
                height: 20px;
                margin: 4px 19px 0 0;
                padding: 3px 10px 3px 9px;
                border-radius: 12.5px;
                background-color: rgba(240, 74, 90, 0.07);
                font-size: 12px;
                font-weight: 500;
                color: #f04a5a;
              }
            }
          }
        }
      }
      .right-content {
        width: 40%;
        height: 165px;
        border-radius: 4px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
        background: #fff;
        display: flex;
        justify-content: space-between;

        .right-text {
          width: 100%;
          padding: 20px;
          .right-title {
            font-size: 20px;
            font-weight: 500;
            color: #354052;
            margin-bottom: 5px;
            user-select: none;
            .real-rebate {
              font-size: 12px;
              color: #354052;
              margin-left: 10px;
              .rebate-value {
                color: #f04a5a;
              }
            }
          }
          .right-text-item {
            width: 100%;
            height: 100px;
            padding: 13px 16px 12px 15px;
            border-radius: 4px;
            background-color: #f9f9fa;
            display: flex;
            justify-content: space-between;
            .invite {
              margin-top: 2px;
              font-size: 12px;
              color: #354052;
              .iconfont {
                color: #9aa1b1;
                margin-left: 10px;
                cursor: pointer;
              }
            }
            .invite_link_outer {
              font-size: 12px;
              color: #354052;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 2px;
              .inviteIcon {
                // margin-top: 6px;
                color: #9aa1b1;
                margin-left: 10px;
                cursor: pointer;
              }
              .invite {
                overflow: hidden;
                width: 290px;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
            .left {
              .top-text {
                color: #354052;
                font-size: 14px;
                font-weight: 500;
                .red {
                  font-size: 16px;
                  font-weight: bold;
                  color: #f04a5a;
                }

                .ask {
                  color: #9aa1b1;
                  margin-left: 10px;
                  cursor: pointer;
                  font-size: 18px;
                }
              }
            }
            .right {
              text-align: right;
              user-select: none;
              p {
                cursor: pointer;
                height: 20px;
                margin-bottom: 20px;
                font-size: 12px;
                color: #354052;
                .editcolor {
                  color: #9aa1b1;
                }
              }
            }
          }
        }
        .btn-v2 {
          user-select: none;
          cursor: pointer;
          width: 120px;
          height: 32px;
          padding: 6px 20px;
          border-radius: 3px;
          background-image: linear-gradient(73deg, #c61b4e 21%, #ee244c 82%);
          color: #fff;
          font-size: 14px;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
          /* user-select: none;
          cursor: pointer;
          width: 120px;
          height: 16px;
          padding: 6px 32px;
          border-radius: 3px;
          background-image: linear-gradient(73deg, #c61b4e 21%, #ee244c 82%);
          color: #fff;
          font-size: 14px;
          font-weight: 500; */
        }
      }
    }
    .header {
      border-radius: 4px;
      .content-title {
        background-color: #fff;
        position: relative;
        display: flex;
        align-items: center;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
        background-color: #ffffff;
        user-select: none;
        // border-radius: 4px;
        h2 {
          font-size: 26px;
          font-weight: 600;
          color: #354052;
          text-align: left;
          height: 80px;
          line-height: 80px;
          padding-left: 24px;
          // padding: 21px 0 21px 24px;
        }
        .sub-title {
          width: fit-content;
          height: 24px;
          line-height: 24px;
          margin: 7px 0 6.5px 6px;
          padding: 0 10px;
          border-radius: 12px;
          background-image: linear-gradient(240deg, #ff486d 7%, #c61b3d 94%);
          color: #ffffff;
          font-size: 12px;
        }
      }
      .nav {
        display: flex;
        flex: 1;
        justify-content: space-between;
        font-size: 14px;
        .profitmessage {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          .exchange-rate {
            font-size: 14px;
            line-height: 14px;
            color: #354052;
          }
          .profit-loss {
            font-size: 14px;
            line-height: 14px;
            color: #354052;
            .profit {
              color: #03ad79;
            }
            .loss {
              color: red;
            }
          }
        }
        .list-type {
          display: flex;
          .tab {
            position: relative;
            padding: 0px 0px 10px 0px;
            margin-right: 22px;
            color: #959ba7;
            line-height: 22px;
            cursor: pointer;
            span {
              font-size: 16px;
              font-weight: 400;
            }
            &:hover {
              color: #354052;
              &:after {
                content: "";
                height: 2px;
                width: 80%;
                background-color: #c61b3d;
              }
            }
            &.active {
              color: #354052;
              font-weight: 400;
              font-size: 16px;
              &:after {
                content: "";
                height: 3px;
                width: 20px;
                background-color: #c61b3d;
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
        }
      }
      .assets {
        margin-top: 16px;
        background-color: #fff;
        min-height: 854px;
      }
    }

    .spread-uncter-content {
      /* min-height: 800px; */
      min-height: 757px;
      padding: 0px 20px;
      background: #ffffff;
      margin: 20px 20px 20px 20px;
      border-radius: 4px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);

      .tab-content {
        user-select: none;
        background-color: #fff;
        border-bottom: 1px solid #ecedf1;

        ul {
          display: flex;
        }
        .classify-area {
          padding: 10px 15px;
          cursor: pointer;
          font-size: 16px;
          // height: 32px;
          line-height: 32px;
          color: #354052;
          position: relative;
          &:first-child {
            margin-left: -15px;
          }
          &.active {
            font-weight: 500;
            background-color: #ffffff;
            color: #354052;
          }
          .item-bar {
            position: absolute;
            width: 70%;
            height: 3px;
            background-color: #e81b40;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
          }
        }
      }
      /* .operate-cell {
        right: 30px;
      } */
      .el-table {
        /* min-height: 650px; */
        min-height: 570px;
        th > .cell {
          padding-right: 30px;
        }
        .cell {
          padding-right: 30px;
        }
      }
      .no-data-box {
        /* min-height: 600px; */
        min-height: 520px;
      }
      .el-table__row {
        height: 50px !important;
        .cell {
          color: #354052;
          font-size: 14px;
          // display: flex;
          align-items: center;
        }
      }
      .coin-ing {
        width: 28px;
        height: 28px;
        margin-right: 8px;
        vertical-align: middle;
      }
      .text {
        vertical-align: middle;
      }
      .persent {
        color: #f04a5a;
      }
      .total-msg-box {
        border-radius: 4px;
        background-color: #f9f9fa;
        height: 48px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: #354052;
        padding-left: 15px;
        .msg-num {
          display: inline-block;
          padding: 0 20px 0 4px;
          font-size: 16px;
          font-weight: bold;
          color: #f04a5a;
        }
      }
      .invite-code-box {
        height: 40px;
        margin-top: 15px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .search-content {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .title-box {
            height: 100%;
            font-size: 20px;
            font-weight: 500;
            color: #354052;
            display: flex;
            align-items: center;
            justify-content: center;
            /* width: 120px; */
            margin-right: 20px;
          }
          .search-code {
            width: 150px;
            margin-right: 20px;
          }
          .search-remark {
            width: 150px;
            margin-right: 20px;
          }
          .tenWid {
            width: 10px;
            height: 1px;
            display: inline-block;
            position: relative;
          }
          .twentyWid {
            width: 20px;
            height: 1px;
            display: inline-block;
            position: relative;
          }
          .btn-search {
            user-select: none;
            cursor: pointer;
            /* width: 120px; */
            /* height: 16px; */
            padding: 10px 21px;
            border-radius: 3px;
            background-image: linear-gradient(73deg, #c61b4e 21%, #ee244c 82%);
            color: #fff;
            font-size: 14px;
          }
          .btn-reset {
            user-select: none;
            cursor: pointer;
            /* width: 120px; */
            /* height: 16px; */
            padding: 9px 20px;
            border-radius: 3px;
            color: #354052;
            font-size: 14px;
            border: solid 1px #c2c7d0;
            box-sizing: border-box;
          }
        }

        .btn-invite {
          user-select: none;
          cursor: pointer;
          /* width: 120px; */
          height: 32px;
          padding: 6px 20px;
          border-radius: 3px;
          background-image: linear-gradient(73deg, #c61b4e 21%, #ee244c 82%);
          color: #fff;
          font-size: 14px;
          font-weight: 500;
        }
      }
      .friend-rebate-box {
        height: 40px;
        margin-top: 15px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        .search-content {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .title-box {
            height: 100%;
            font-size: 20px;
            font-weight: 500;
            color: #354052;
            display: flex;
            align-items: center;
            justify-content: center;
            /* width: 120px; */
          }
          .search-code {
            width: 150px;
          }
          .search-remark {
            width: 150px;
          }
          .tenWid {
            width: 10px;
            height: 1px;
            display: inline-block;
            position: relative;
          }
          .twentyWid {
            width: 20px;
            height: 1px;
            display: inline-block;
            position: relative;
          }
          .btn-search {
            user-select: none;
            cursor: pointer;
            /* width: 120px; */
            /* height: 16px; */
            padding: 10px 21px;
            border-radius: 3px;
            background-image: linear-gradient(73deg, #c61b4e 21%, #ee244c 82%);
            color: #fff;
            font-size: 14px;
          }
          .btn-reset {
            user-select: none;
            cursor: pointer;
            /* width: 120px; */
            /* height: 16px; */
            padding: 9px 20px;
            border-radius: 3px;
            color: #354052;
            font-size: 14px;
            border: solid 1px #c2c7d0;
            box-sizing: border-box;
          }
        }
      }
      .table-icon-copy {
        vertical-align: -4%;
        margin-left: 10px;
        color: #9aa1b1;
        cursor: pointer;
      }
      .is-default-code {
        color: #f04a5a;
        margin-left: 10px;
        font-weight: 500;
        border-radius: 12.5px;
        border: solid 1px #f04a5a;
        padding: 0px 10px;
        font-size: 12px;
      }
      .operation {
        font-size: 14px;
        color: #3a7dff;
        font-weight: 400;
        cursor: pointer;
        padding: 0px 0px 0px 20px;
        /* padding: 0px 20px 0px 0px; */
      }
      .operation-ban {
        font-size: 14px;
        color: #9aa1b1;
        font-weight: 400;
        padding: 0px 0px 0px 20px;
        /* padding: 0px 20px 0px 0px; */
      }
      .filter-box {
        /* padding: 20px 0px 15px 0px; */
        margin: 15px 0px 10px 0px;
        .tenWid {
          width: 10px;
          height: 1px;
          display: inline-block;
          position: relative;
        }
        .twentyWid {
          width: 20px;
          height: 1px;
          display: inline-block;
          position: relative;
        }
        .btn-search {
          user-select: none;
          cursor: pointer;
          width: 120px;
          height: 16px;
          padding: 10px 21px;
          border-radius: 3px;
          background-image: linear-gradient(73deg, #c61b4e 21%, #ee244c 82%);
          color: #fff;
          font-size: 14px;
        }
        .btn-reset {
          user-select: none;
          cursor: pointer;
          width: 120px;
          height: 16px;
          padding: 9px 20px;
          border-radius: 3px;
          color: #354052;
          font-size: 14px;
          border: solid 1px #c2c7d0;
          box-sizing: border-box;
        }
        .btn-export {
          user-select: none;
          cursor: pointer;
          /* width: 120px; */
          padding: 10px 12px;
          border-radius: 3px;
          color: #354052;
          font-size: 14px;
          border: solid 1px #c2c7d0;
          box-sizing: border-box;
          float: right;
          .export-icon {
            color: #9aa1b1;
            margin-right: 4px;
          }
        }
        .el-input {
          width: 200px;

          .el-input__inner {
            border: solid 1px #e2e2e4;
            box-sizing: border-box;
            border-radius: 3px;
          }
        }
        .el-checkbox {
          margin-left: 20px;
          .el-checkbox__label {
            font-size: 14px;
            color: #354052;
            font-weight: 400;
          }
        }
      }
      /* .operation {
        font-size: 14px;
        color: #3a7dff;
        font-weight: 400;
        cursor: pointer;
        padding: 0px 20px 0px 0px;
      }
      .operation-ban {
        font-size: 14px;
        color: #9aa1b1;
        font-weight: 400;
        padding: 0px 20px 0px 0px;
      } */
    }
  }
  .confirm-dialog {
    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }
    .el-dialog__body {
      text-align: center;
      color: #354052;
      padding: 20px 20px 30px;
      font-size: 16px;
      font-weight: normal;
    }
  }
}
</style>
